import React, { useEffect, useState } from 'react';

import { get_contributors } from '../api'
import { FotoContributor } from '../components'
import { chunk } from '../utils'
import { login_url } from '../globals'
import { useLogin } from '../reducers';


type Contributors = Awaited<ReturnType<typeof get_contributors>>
const get_division_from_window_size = () => {
  const horiz = (window.innerWidth > window.innerHeight);
  return {
    divisione_developers: horiz ? 3 : 1,
    divisione_contributors: horiz ? 4 : 2,
    size_developer: horiz ? 10 : 40,
    size_contributor: horiz ? 8 : 20,
  }
}


const TopContributors = () => {
  const login = useLogin();

  const [contributors, setContributors] = useState<Contributors>([]);
  const [horiz, setHoriz] = useState(window.innerWidth > window.innerHeight);

  const check_horizontal = () => {
    const horiz2 = (window.innerWidth > window.innerHeight);
    if (horiz != horiz2) {
      setHoriz(horiz2)
    }
  }

  useEffect(() => {
    document.title = `Top contributors - Owlitrack`
    get_contributors().then(setContributors);
  }, [])

  useEffect(() => {
    window.addEventListener('resize', check_horizontal);
  }, [horiz])

  if (!login.logged_in) {
    login_url()
  }

  const { divisione_contributors, size_contributor } = get_division_from_window_size();
  return (
    <div className="container">
      <h1 className="page-header">Top contributors</h1>
      <p>Su questa pagina vengono elencati i top contributors del sito. Ogni giorno un cron sincronizza con Gascal questa pagina. Non viene riportato su Gascal il punteggio.</p>
      {chunk(contributors, divisione_contributors).map((item, idx1) =>
        <div className="flex-row" key={idx1}>
          {item.map((iitem, idx2) =>
            <FotoContributor
              {...iitem}
              class_name="foto-contributor"
              size={size_contributor}
              key={idx2}
            />)}
        </div>
      )}
    </div>
  )
}

export default TopContributors
