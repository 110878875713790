import React, { useRef, useState, useEffect } from 'react';
import { Popover, Overlay, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";

import { search_problemi_similar } from '../api';
import { useThread, useLogin } from '../reducers'
import { LoadingCircle, Spoiler, ProblemaTabella } from '../components'
import { canSeeVoti } from '../globals';
import {
  displayPartecipante, nice_date,
} from '../utils';


interface IProps {
  id: number
}

type Similar = Awaited<ReturnType<typeof search_problemi_similar>>

const Internal = (props: { id: number, old: any, setShow: (_: boolean) => void }) => {
  const { old, id, setShow } = props;

  const newprops = {
    ...old,
    style: {
      ...old.style,
      maxHeight: "600px",
      maxWidth: "900px",
      overflow: "auto",
    }
  }

  const [similar, setSimilar] = useState<Similar>([]);
  const [bellezzaAperto, setBellezzaAperto] = useState(false);
  const [difficoltaAperto, setDifficoltaAperto] = useState(false);

  const login = useLogin();
  const threads = useThread(props);

  useEffect(() => {
    search_problemi_similar(id).then(setSimilar)
  }, [])

  return (
    <Popover id={`search-problema-similar`} {...newprops}>
      <Popover.Title>
        <span className="mr-2">
          <i className="fa fa-times text-muted" onClick={() => setShow(false)} />
        </span>
        Cerca problemi simili
      </Popover.Title>
      <Popover.Content>
        <div>
          <table className="table table-bordered table-hover table-striped">
            <thead className="thead-dark">
              <tr>
                <th>Similarity</th>
                <th>Titolo</th>
                {canSeeVoti(login.user.permessi) &&
                  <>
                    <th onClick={() => setBellezzaAperto(val => !val)}>Bellezza</th>
                    <th onClick={() => setDifficoltaAperto(val => !val)}>Difficoltà</th>
                  </>
                }
                <th title="Persone che devono correggere il problema">Ass</th>
                <th title="Correzioni">Corr</th>
                <th title="Giuste/Sbagliate">Risp</th>
                <th title="Visualizzazioni">Views</th>
                <th title="Proposto da">Proposto da</th>
                <th title="Ultima modifica">Last</th>
              </tr>
            </thead>
            <tbody>
              {similar.filter(arr => arr[1] > 15).map(arr => {
                const probpk = arr[0];
                const score = arr[1];
                const thread = threads.find(item => item.problema.id == probpk);
                if (!thread) return <LoadingCircle key={probpk} />
                const { id } = login.user;
                const eseguito = thread.problema.correttori_eseguito.includes(id);
                const bellezza_medio = thread.bellezza_medio ? thread.bellezza_medio.toFixed(2) : thread.bellezza_medio;
                const difficolta_medio = thread.difficolta_medio ? thread.difficolta_medio.toFixed(2) : thread.difficolta_medio;
                const bellezza = bellezzaAperto ?
                  bellezza_medio :
                  <Spoiler>
                    {bellezza_medio}
                  </Spoiler>;
                const difficolta = difficoltaAperto ?
                  difficolta_medio :
                  <Spoiler>
                    {difficolta_medio}
                  </Spoiler>;
                return (
                  <tr key={thread.id}>
                    <td>{score.toFixed(1)}</td>
                    <td>
                      <ProblemaTabella item={thread} />
                    </td>
                    {canSeeVoti(login.user.permessi) &&
                      <>
                        <td>{bellezza}</td>
                        <td>{difficolta}</td>
                      </>
                    }
                    <td>
                      {thread.problema.correttori_assegnati.length}
                    </td>
                    <td>
                      {thread.problema.correttori_eseguito.length}
                      {eseguito &&
                        <i className="text-success fa fa-check" />
                      }
                    </td>
                    <td>
                      <span title="Giusto/Sbagliato">
                        {thread.risposto_giusto}/{thread.risposto_totale - thread.risposto_giusto}
                      </span>
                    </td>
                    <td>{thread.visualizzazioni}</td>
                    <td>
                      <Link to={`/utente/${thread.autore}/`}>
                        {displayPartecipante(thread.autore)}
                        <br />
                        <small>
                          {nice_date(thread.data_inizio)}
                        </small>
                      </Link>
                    </td>
                    <td>
                      <small>{nice_date(new Date(thread.ultima_modifica))}</small>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Popover.Content>
    </Popover>
  );
}


export default function SearchProblema(props: IProps) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Button
        className={`mx-1 btn btn-info`} title="Cerca problemi simili" onClick={() => setShow(!show)}
        ref={target}
      >
        <i className="fa fa-search" />
      </Button>
      <Overlay
        placement="top"
        show={show}
        target={target.current}
      >
        {({ ...old }: any) => <Internal id={props.id} setShow={setShow} old={old} />}
      </Overlay>
    </>
  );
}
