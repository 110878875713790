import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router'

import { NuovoCommento } from '../components';
import { login_url } from '../globals'
import { useLogin } from '../reducers'


const EditCommento = () => {
  const login = useLogin();
  const params = useParams<{ pk: string, pk2: string }>();
  const location = useLocation();
  useEffect(() => {
    document.title = `Modifica #${params.pk} - Owlitrack`;
  }, [])
  if (!login.logged_in) {
    login_url()
  }

  const shortlist = !!location.pathname.match(/^\/shortlist\//g)
  return (
    <div className="container">
      <h1 className="page-header">Modifica commento</h1>
      <NuovoCommento
        thread={parseInt(params.pk2 as string)}
        old={parseInt(params.pk as string)}
        shortlist={shortlist}
      />
    </div>
  );
}


export default EditCommento;
