import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom'
import Plot from 'react-plotly.js';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { cloneDeep, isEqual } from 'lodash';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { PermissionDenied, useWebsocket, WebSocketState, WithId } from 'django-rest-react'
import { ThreadLista, Categoria, CategoriaEvento, Evento } from '../api/types'
import {
  patch_versione_shortlist, refresh_shortlist,
  connectModificaShortlist,
  search_problemi_string,
} from '../api'
import {
  displayEvento, displayPartecipante, nice_date,
  fixed_or_null as fon, array_remove as remove, sentry_log, displayBadgeStatoShortlist,
  displayCategoria,
} from '../utils'
import {
  LoadingCircle, ProblemaTabella, Partecipante as PartecipanteComp,
  TimeFeedShortlist, ConfirmedButton, FormGroupBootstrap, NuovoCommento, ErrorBlockGeneric
} from '../components'
import { correzioni_richieste, themeColors, STATO_SHORTLIST, canSeeShortlist, ORDINE_SHORTLIST, login_url, COMPETENZA_COLORS } from '../globals';
import store from '../store'
import { add_toast_action } from '../actions'
import { useCategoriaEvento, useLogin, useShortlist, useCategoria, useThread, useEvento, useCompetenza } from '../reducers';
import { post_thread_shortlist_detail_assegna_evento } from '../api/auto-apis';
import { empty_initial_values, filtra, FormValues as FormValuesFiltra, SortFunc } from '../screens/ListaThreadProblemi'


interface FormValues {
  ordine: string,
  stato: 'a' | 'p' | 'r'
}

interface ThreadListaOrdinato extends ThreadLista {
  ordine: number
}


interface esclusiOwnProps {
  esclusi: ThreadLista[],
  lista_problemi: number[],
  setOrdineAndNotify: (_: string) => void
}



function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


const ProblemiEsclusi = (props: esclusiOwnProps) => {

  const categorie = useCategoria(props);
  const categorieevento = useCategoriaEvento(props);
  const eventi = useEvento(props);
  const login = useLogin();
  const params = useParams();
  const location = useLocation();

  const [order, setOrder] = useState(-1);
  const [sortParam, setSortParam] = useState(() => (thr => thr.id) as SortFunc)
  const [searchPkList, setSearchPkList] = useState<number[]>([])

  const changeSorting = (fun: SortFunc) => {
    setOrder(ord => -1 * ord);
    setSortParam(() => fun);
  }

  const { esclusi, lista_problemi, setOrdineAndNotify } = props;

  return (
    <Formik
      initialValues={cloneDeep(empty_initial_values)}
      onSubmit={(values: FormValuesFiltra, actions: FormikActions<FormValuesFiltra>) => {
        if (values.ricerca_diretta != "") {
          search_problemi_string(values.ricerca_diretta).then(results => results.map(val => val[0])).then(setSearchPkList)
        }


        actions.setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, handleReset }) => {
        const filtrati = filtra(login, params, location, values, esclusi, sortParam, order, searchPkList);
        return (
          <>
            <Form>
              <FormGroupBootstrap
                name="categorie" type="select-multiple"
                choices={categorie}
                displayChoice={(item: Categoria) => displayCategoria(item.id)}
              />
              <FormGroupBootstrap
                name="eventi" type="select-multiple"
                displayName="Evento assegnato"
                choices={eventi}
                displayChoice={(item: Evento) => displayEvento(item.id)}
                help_text="Questo è uno dei due filtri inteso come OR. Se viene selezionato più di un evento, vengono selezionati i problemi assegnati per il primo evento OPPURE per il secondo, e via dicendo."
              />
              <FormGroupBootstrap
                name="categorieevento" type="select-multiple"
                choices={categorieevento}
                displayChoice={(item: CategoriaEvento) => item.nome}
                displayName="Categoria evento"
                help_text="Anche questo è inteso come OR"
              />
              <FormGroupBootstrap
                name="solo_non_assegnati" type="checkbox"
                displayName="Solo non assegnati"
                help_text="Solo problemi non ancora utilizzati in un evento."
              />
              <FormGroupBootstrap
                name="mostra_scartati_duplicati" type="checkbox" help_text="Mostra anche i problemi marchiati come duplicati o scartati."
              />
              <FormGroupBootstrap
                name="ricerca_diretta" type="text" help_text="Ricerca per testo"
              />
              <div className="text-muted">
                <p>Premi il pulsante filtra per rendere persistente il filtro a delle aggiunte di problemi.</p>
              </div>
              <div className="d-flex justify-content-end">
                <ConfirmedButton
                  type="warning"
                  onSubmit={handleReset}>
                  Reset
                </ConfirmedButton>
                <button type="submit" disabled={isSubmitting}
                  className="mx-1 btn btn-primary">Filtra</button>
              </div>
            </Form>
            <div className="table-responsive">
              <table className="table table-bordered table-hover table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th
                      title="Ordina per id"
                      onClick={() => changeSorting(thr => thr.id)}
                    >
                      <i className="fa fa-sort" /> Problema</th>
                    <th
                      title="Ordina per bellezza"
                      onClick={() => changeSorting(thr => thr.bellezza_medio)}
                    >
                      <i className="fa fa-sort" /> Bellezza</th>
                    <th
                      title="Ordina per difficoltà"
                      onClick={() => changeSorting(thr => thr.difficolta_medio)}
                    >
                      <i className="fa fa-sort" /> Difficoltà</th>
                    <th>Visualizzazioni</th>
                    <th>Autore</th>
                    <th>Ultima modifica</th>
                    <th>Aggiungi</th>
                  </tr>
                </thead>
                <tbody>
                  {filtrati.map(pthread => {
                    return (
                      <tr key={pthread.id}>
                        <td><ProblemaTabella item={pthread} /></td>
                        <td>{fon(pthread.bellezza_medio)}</td>
                        <td>{fon(pthread.difficolta_medio)} ({pthread.problema.difficolta_automatica.toFixed(2)})</td>
                        <td>{pthread.visualizzazioni}</td>
                        <td>
                          <Link to={`/utente/${pthread.autore}/`}>
                            {displayPartecipante(pthread.autore)}
                            <br />
                            <small>
                              {nice_date(pthread.data_inizio)}
                            </small>
                          </Link>
                        </td>
                        <td>
                          <small>{nice_date(pthread.ultima_modifica)}</small>
                        </td>
                        <td>
                          <button
                            className="btn btn-success"
                            title="Aggiungi il problema alla lista dei papabili"
                            onClick={() => {
                              lista_problemi.push(pthread.id);
                              return setOrdineAndNotify(lista_problemi.join(","))
                            }}
                          >
                            <i className="fa fa-check" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        );
      }}
    </Formik>
  )
}


const calcola_grafico = (primi_n: ThreadLista[], categorie: ReturnType<typeof useCategoria>, competenze: ReturnType<typeof useCompetenza>) => {
  const pie_data = [
    {
      values: categorie.map(item => {
        let retval = 0;
        for (const val of primi_n) {
          if (val.problema.categoria.includes(item.id)) {
            retval += 1 / val.problema.categoria.length;
          }
        }
        return retval;
      }),
      labels: categorie.map(item => item.nome),
      marker: {
        colors: categorie.map(item => themeColors[item.colore]),
      },
      type: "pie" as "pie",
      name: "Distribuzione categorie"
    },
  ]
  const nice_diff_data = [
    {
      x: primi_n.map(item => item.bellezza_medio),
      xbins: {
        start: 1,
        end: 5,
        size: 0.5,
      },
      type: "histogram" as "histogram",
      name: "Bellezza media",
    },
    {
      x: primi_n.map(item => item.difficolta_medio),
      xbins: {
        start: 1,
        end: 5,
        size: 0.5,
      },
      type: "histogram" as "histogram",
      name: "Difficoltà media",
    },
  ]
  const pie_data_competenze = [
    {
      values: competenze.map(comp => {
        return primi_n.filter(prob => prob.problema.competenze?.includes(comp.id)).length
      }),
      labels: competenze.map(item => item.nome),
      marker: {
        colors: competenze.map(item => themeColors[COMPETENZA_COLORS[item.tipologia]]),
      },
      type: "pie" as "pie",
      name: "Competenze richieste",
    }
  ]
  return {
    pie_data,
    nice_diff_data,
    pie_data_competenze
  }
}


const ShortlistDettaglio = (props: {}) => {

  const [connessi, setConnessi] = useState<number[]>([]);
  const [error, setError] = useState(null);
  const [commenta, setCommenta] = useState(false);
  const [mostraEsclusi, setMostraEsclusi] = useState(false);

  const threads = useThread(props);
  const login = useLogin();
  const shortlists = useShortlist(props);
  const categorie = useCategoria(props);
  const eventi = useEvento(props);
  const competenze = useCompetenza(props);

  const wss = useWebsocket();
  const params = useParams<{ pk: string }>();

  useEffect(() => {
    document.title = `Shortlist #${params.pk} - Owlitrack`;
  }, [])


  if (!login.logged_in) {
    login_url()
  }
  if (!canSeeShortlist(login.user)) {
    throw new PermissionDenied({ message: "Non hai abbastanza permessi per vedere le shortlist." })
  }

  // TODO: Rendere size inseribile da form
  const pk = parseInt(params.pk as string);
  const thread = shortlists.find(item => item.id === pk);
  if (!thread) {
    return <LoadingCircle />
  }
  const { shortlist } = thread;
  const last = shortlist.versioni[shortlist.versioni.length - 1]
  const initialValues = {
    ordine: last.ordine,
    stato: last.stato
  }
  return (
    <div className="container">
      <h1 className="page-header">{shortlist.titolo}</h1>
      {shortlist.evento &&
        <h3>{`Shortlist per l'evento ${displayEvento(shortlist.evento)}`}</h3>}
      <Formik
        initialValues={initialValues} onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
          const submitting_values = {
            versioni: [
              {
                stato: values.stato,
                ordine: values.ordine,
              }
            ],
          }
          const thread = shortlists.find(item => item.id === pk);
          if (!thread) {
            sentry_log(new Error("Non trovo il thread per submittare la shortlist"))
            actions.setSubmitting(false);
            return;
          }
          const { shortlist } = thread;
          patch_versione_shortlist(shortlist.id, submitting_values)
            .then(ans => {
              actions.setSubmitting(false);
              refresh_shortlist();
              store.dispatch(add_toast_action({
                title: "Modifiche salvate sul server",
                message: <>
                  <p>Le modifiche sono state correttamente salvate sul server</p>
                </>
              }))
            })
            .catch(error => {
              sentry_log(error)
              setError(error)
              actions.setErrors(error);
              actions.setSubmitting(false);
            });
        }
        }
        enableReinitialize={true}
      >
        {({ values, isSubmitting, setFieldValue, handleReset }) => {
          useEffect(() => {
            connectModificaShortlist(
              wss.current, shortlist.id,
              ordine => setFieldValue("ordine", ordine),
              (nuovo: number, state: "add" | "del") => {
                setConnessi(people_list => {
                  console.log("Stato mandato", state, nuovo, people_list)
                  if (state != "add") {
                    return people_list.filter(item => item != nuovo)
                  }
                  if (people_list.indexOf(nuovo) == -1) {
                    return [nuovo, ...people_list]
                  }
                  return people_list
                })
              }
            )
          }, [])

          let visualizzazione_contemporanea;
          if (wss.current.wss == WebSocketState.Connected) {
            visualizzazione_contemporanea = (
              <div className="d-flex flex-wrap my-1">{connessi.map(item =>
                <PartecipanteComp
                  key={item} partecipante={item}
                />)}</div>
            )
          } else if (wss.current.wss == WebSocketState.Disconnected) {
            visualizzazione_contemporanea = (
              <div>Errore</div>
            )
          } else {
            visualizzazione_contemporanea = (
              <p>Disconnesso</p>
            )
          }
          const __appo_ws = wss.current.ws;
          const setOrdineAndNotify = (nuovo_ordine: string) => {
            setFieldValue("ordine", nuovo_ordine);
            if (wss.current.wss == WebSocketState.Connected) {
              __appo_ws.send(JSON.stringify({
                type: "new_ordine", ordine: nuovo_ordine
              }))
            }
          };

          const modificato = !isEqual(values, initialValues);
          const lista_problemi = values.ordine ?
            values.ordine.split(',').map(item => parseInt(item)) :
            []
          const primi_n_ = lista_problemi.map(
            (item, idx) => {
              let appo = threads.find(pitem => pitem.id == item) as ThreadListaOrdinato
              if (!appo) {
                return null
              }
              appo.ordine = idx + 1;
              return appo;
            });
          const missing_threads = primi_n_.reduce((acc, item) => acc || (item === null), false);
          const primi_n = missing_threads ? [] : primi_n_ as ThreadListaOrdinato[];
          const bellezza_media = (primi_n.reduce(
            (acc, val) => acc + val.bellezza_medio, 0) / primi_n.length).toFixed(2);
          const difficolta_media = (primi_n.reduce(
            (acc, val) => acc + val.difficolta_medio, 0) / primi_n.length).toFixed(2);
          const problemi_da_correggere = primi_n.reduce(
            (acc, val) => acc + ((val.problema.correttori_eseguito.length >= correzioni_richieste) ? 0 : 1),
            0)
          const { pie_data, nice_diff_data, pie_data_competenze } = calcola_grafico(primi_n, categorie, competenze);
          const alert_correggere_class = (problemi_da_correggere > 0) ? "text-danger" : undefined;
          const alert_correggere = (problemi_da_correggere > 0) ?
            <span className="badge badge-danger mx-1">Attenzione</span> :
            <span className="badge badge-success mx-1">Gara validata</span>;

          const onDragEnd = (result: DropResult) => {
            if (!result.destination) {
              return;
            }
            setOrdineAndNotify(reorder(
              lista_problemi,
              result.source.index,
              result.destination.index,
            ).join(","));
          }


          let esclusi;
          let extra_message = "(tutti gli altri problemi)"
          if (shortlist.parent) {
            const pparent = shortlists.find(item => item.shortlist &&
              item.shortlist.id == shortlist.parent);
            if (!pparent) {
              return <LoadingCircle />
            }
            extra_message = `(dalla shortlist ${pparent.titolo})`
            const parent = pparent.shortlist;
            const last = parent.versioni[parent.versioni.length - 1]
            const nuova_lista = last.ordine.split(",").map(item => parseInt(item))
            esclusi = threads
              .filter(item => nuova_lista.includes(item.id))
              .filter(item => !lista_problemi.includes(item.id))

          } else {
            esclusi = threads.filter(item => !lista_problemi.includes(item.id))

          }
          const data_evento = eventi.find(ev => ev.id === shortlist.evento)?.data
          const evento_passato = !!data_evento ? (new Date(data_evento).getTime()) < (new Date().getTime()) : false;
          return (
            <>
              <div className="card mb-2">
                <div className="card-header">
                  <h4 className="card-title">Edit simultaneo</h4>
                </div>
                <div className="card-body">
                  {visualizzazione_contemporanea}
                </div>
              </div>
              {evento_passato &&
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Assegna ad evento</h4>
                    <p>{"L'evento per questa shortlist è passato, vuoi assegnare i problemi a questo evento?"}</p>
                    <ConfirmedButton type="danger" onSubmit={() => post_thread_shortlist_detail_assegna_evento(pk, {} as any)}>Assegna</ConfirmedButton>
                  </div>

                </div>
              }
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Export</h4>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{ order: "ordine", rev: false }}
                    onSubmit={() => {}}
                  >
                    {({ values }) => {
                      return (
                        <Form className="form needs-validation">
                          <FormGroupBootstrap
                            type="select" choices={ORDINE_SHORTLIST} name="order"
                            displayName="Ordine"
                          />
                          <FormGroupBootstrap
                            type="checkbox" name="rev" displayName="Inverti ordine"
                          />
                          <a href={`/api/problems/shortlist/${pk}/export/?order=${values.order}&rev=${values.rev}`}
                            className="btn btn-info" >Scarica in LaTeX</a>
                        </Form>
                      )
                    }}
                  </Formik>
                  <Link to={`/shortlist/build/${thread.id}/testo/`} className="btn btn-success" title="TeX testo"><span className="fa fa-hammer" /> Overleaf testo</Link>
                  <Link to={`/shortlist/build/${thread.id}/soluzioni/`} className="btn btn-info" title="TeX soluzioni"><span className="fa fa-hammer" /> Overleaf soluzioni </Link>
                  <Formik
                    initialValues={{ evento: 1, tipologia: 'gas' }}
                    onSubmit={() => {}}
                  >
                    {({ values }) => {
                      const path = values.tipologia === "gas" ? "export_risposte_gascal" : "export_risposte_stagefisica";
                      const url = `/api/problems/shortlist/${pk}/${path}/?evento=${values.evento}`;

                      return (
                        <Form className="form needs-validation mt-2">
                          <FormGroupBootstrap
                            name="evento" type="number" step={1}
                          />
                          <FormGroupBootstrap
                            name="tipologia" type="select"
                            choices={[{ id: "gas", display: "Gascal" }, { id: "preteststage", display: "Pretest stage" }] as unknown as WithId[]}
                          />
                          <a href={url} className="btn btn-info" title="Export risposte">Esporta le risposte</a>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3>Dettagli sui primi {primi_n.length} problemi</h3>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <h3>Stato della shortlist: {displayBadgeStatoShortlist(last.stato)}</h3>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      Bellezza media: {bellezza_media}
                    </div>
                    <div>
                      Difficoltà media: {difficolta_media}
                    </div>
                    <div className={alert_correggere_class}>
                      {alert_correggere}
                      Problemi ancora da correggere: {problemi_da_correggere}
                    </div>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Plot
                      data={nice_diff_data}
                      layout={{ width: 500, height: 500, title: "Distribuzione bellezza e difficoltà" }}
                    />
                    <Plot
                      data={pie_data}
                      layout={{ width: 500, height: 500, title: "Distribuzione delle categorie", barmode: "overlay" }}
                    />
                  </div>
                  <div className="d-flex flex-wrap">
                    <Plot
                      data={pie_data_competenze}
                      layout={{ width: 1000, height: 500, title: "Competenze richieste nella shortlist" }}
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered table-hover table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th>Ordine di preferenza</th>
                      <th>Problema</th>
                      <th>Bellezza</th>
                      <th>Difficoltà</th>
                      <th>Visualizzazioni</th>
                      <th>Autore</th>
                      <th>Ultima modifica</th>
                      <th>Elimina</th>
                    </tr>
                  </thead>

                  <DragDropContext onDragEnd={onDragEnd} >
                    <Droppable droppableId="problemi_shortlist">
                      {(provided, snapshot) =>
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {primi_n.map((item, idx) => {
                            // La key indicata sotto è necessaria, se si usa idx o thread.id e basta,
                            // quando vengono scambiati react si kekka e non procede a renderizzare nuovamente
                            // i componenti, rompendo il drag-n-drop
                            return (
                              <Draggable key={item.id * 10000 + idx} draggableId={item.id.toString()} index={idx}>
                                {(provided, snapshot) => {
                                  return (
                                    <tr
                                      key={item.id}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <td>{item.ordine}</td>
                                      <td><ProblemaTabella item={item} /></td>
                                      <td>{fon(item.bellezza_medio)}</td>
                                      <td>{fon(item.difficolta_medio)} ({item.problema.difficolta_automatica.toFixed(2)})</td>
                                      <td>{item.visualizzazioni}</td>
                                      <td>
                                        <Link to={`/utente/${item.autore}/`}>
                                          {displayPartecipante(item.autore)}
                                          <br />
                                          <small>
                                            {nice_date(item.data_inizio)}
                                          </small>
                                        </Link>
                                      </td>
                                      <td>
                                        <small>{nice_date(new Date(item.ultima_modifica))}</small>
                                      </td>
                                      <td>
                                        <button className="btn btn-danger" title="Elimina"
                                          onClick={() => setOrdineAndNotify(
                                            remove(lista_problemi, idx).join(","))}
                                        >
                                          <i className="fa fa-times" />
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </tbody>
                      }
                    </Droppable>
                  </DragDropContext>
                </table>
                <Form className="form needs-validation">
                  <FormGroupBootstrap
                    name="stato"
                    type="select"
                    choices={STATO_SHORTLIST}
                  />
                  <div className="d-flex justify-content-end mb-2">
                    <ConfirmedButton
                      type="warning"
                      onSubmit={handleReset}>
                      Reset
                    </ConfirmedButton>
                    <ErrorBlockGeneric error={error} />
                    <button className="btn btn-primary" type="submit"
                      disabled={(isSubmitting || !modificato)}>
                      {modificato ? "Salva una nuova versione" : "Nessuna modifica effettuata"}
                    </button>
                  </div>
                </Form>
              </div>
              <h1>Problemi esclusi {extra_message}</h1>
              <button className="btn btn-info my-1" type="button"
                onClick={() => setMostraEsclusi(m => !m)}>
                Toggle visualizzazione (è ingombrante)
              </button>
              {mostraEsclusi &&
                <ProblemiEsclusi
                  esclusi={esclusi} lista_problemi={lista_problemi}
                  setOrdineAndNotify={setOrdineAndNotify}
                  key={esclusi.length}
                />
              }

              <h1>Discussione</h1>
              <TimeFeedShortlist versioni={shortlist.versioni} threadpk={pk} />
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-primary" type="button"
                  onClick={() => setCommenta(c => !c)}
                >
                  Commenta
                </button>
              </div>
              {commenta && <NuovoCommento thread={pk} />}
            </>)
        }}
      </Formik>
    </div>
  );
}


export default ShortlistDettaglio;
