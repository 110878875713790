import { display_cached } from 'django-rest-react';
import { CategoriaEvento, Evento, Categoria, User, Partecipante } from '../api/types';
import { evento_all, categoriaevento_all, categoria_all, partecipanti_all } from '../reducers';


export function displayEvento(pk: number | string, displayfunc = (e: Evento) => e.titolo) {
  const real_pk = (typeof pk === "string") ? parseInt(pk) : pk;
  return display_cached(evento_all, real_pk, displayfunc);
}

export function displayCategoriaEvento(pk: number | string, displayfunc = (e: CategoriaEvento) => e.nome) {
  const real_pk = (typeof pk === "string") ? parseInt(pk) : pk;
  return display_cached(categoriaevento_all, real_pk, displayfunc);
}

export function displayCategoria(pk: number | string, displayfunc = (e: Categoria) => e.nome) {
  const real_pk = (typeof pk === "string") ? parseInt(pk) : pk;
  return display_cached(categoria_all, real_pk, displayfunc);
}

export function displayCategoriaColore(pk: number | string, displayfunc = (e: Categoria) => e.colore) {
  const real_pk = (typeof pk === "string") ? parseInt(pk) : pk;
  return display_cached(categoria_all, real_pk, displayfunc);
}

export function displayUser(user: User) {
  return user.first_name + " " + user.last_name;
}

export function displayPartecipante(
  pk: number | null, displayfunc = (p: Partecipante) => displayUser(p.user)) {
  if (!pk) return "Anonimo";
  return display_cached(partecipanti_all, pk, displayfunc);
}
