import React from 'react';
import { Link } from 'react-router-dom';

import { displayPartecipanteBadge, nice_date } from '../utils';
import { ThreadLista, ProblemaVersione } from '../api/types';
import LoadingCircle from './LoadingCircle';
import { usePartecipante } from '../reducers';


interface ownProps {
  versione: Omit<ProblemaVersione, "testo">,
  link?: boolean,
  problemi: ThreadLista[]
}


const getLink = (p: ThreadLista | undefined) => {
  if (!p) return <LoadingCircle />
  return <><Link to={`/thread/problema/${p.id}/`}>{p.titolo}</Link></>
}

const ModificaProblemaFeed = (props: ownProps) => {
  const partecipanti = usePartecipante(props);
  const { versione } = props;
  const part = partecipanti.find(i => (i.id == versione.autore));
  if (!part) {
    return <LoadingCircle />;
  }
  const p = props.problemi.find(item => (item.problema.id == versione.problema));
  const link = props.link ? getLink(p) :
    null
    ;
  return (
    <>
      <span className="my-2">
        <i className="fa fa-edit mx-2" />
        {nice_date(versione.data)} {displayPartecipanteBadge(part)} ha modificato il testo del problema {link}.
      </span>
      <br />
    </>
  );
}

export default ModificaProblemaFeed;
