import { WithId } from 'django-rest-react'

export interface Displayable extends WithId {
  display: string
}

export {
  PROBLEMA_FONTE as FONTE,
  VERSIONESHORTLIST_STATO_SHORTLIST as STATO_SHORTLIST,
  FLAGSMISTATORE_FLAGS as FLAGS_SMISTATORE,
  PARTECIPANTE_PERMESSI as PERMESSI,
} from './auto-enums';


export const COMPETENZA_COLORS = {
  gen: "concrete",
  mate: "danger",
  fis: "emerald",
  mec: "wet-asphalt",
  term: "pomegranate",
  em: "electric-yellow",
  ottica: "clouds",
}
