import React from 'react';
import * as Sentry from '@sentry/browser';
import { Provider } from "react-redux";
import { Route, createHashRouter, RouterProvider, createRoutesFromElements, Outlet } from "react-router-dom";

import store from '../store';

import { sentry_config, DEBUG, TESTING } from '../globals';
import {
  Navbar, LoadingBar, APIErrorCatcher,
  LoginError, ToastFeed, Footer
} from '../components';
import { Loadable } from '../common-components'
import {
  Home, ThreadProblema, ListaThreadProblemi, NoMatch,
  NuovoThreadProblema, RispondiProblema, PartecipanteDettagli,
  EditCommento, Profile, About, AssegnaProblema, ListaPartecipanti,
  UltimeModifiche, GuidaDifficolta, Costanti, VotiThreadProblema,
  Bozze, EditBozza, Changelog, TopContributors, ListaShortlist, ShortlistDettaglio,
  NuovaShortlist, LineeGuida, OverleafShortlist
} from '../screens';
import { get_whoami, subscribe } from '../api';
import {
  dedicated_worker_handler, dedicated_worker_error_handler,
  service_worker_handler
} from '../handlers';

import { set_user_data, create_registration, add_toast_action, delete_old_toasts } from '../actions';
import { plain_object, get_csrf_token, configure_drr } from 'django-rest-react';
import { reducers_map } from '../reducers'
import { createRoot } from 'react-dom/client';


const LayoutFactory = (elem: React.ReactNode, effects: boolean) => () => {
  return (
    <div className="d-flex flex-column justify-content-between" id="contenitore">
      <div>
        <LoadingBar />
        <Navbar />
        <br />
        <div style={{ position: 'relative', zIndex: 3 }}>
          <div style={{ position: 'relative', zIndex: 1000 }}>
            <ToastFeed />
          </div>
          <div style={{ position: 'relative', zIndex: 1 }}>
            {elem}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}


const Layout = LayoutFactory(<Outlet />, true)
const ErrorElement = LayoutFactory(<APIErrorCatcher get_username={s => s.user.user.username} />, false)


export const ROUTES = (
  <Route path="/*" element={<Layout />} errorElement={<ErrorElement />}>
    <Route index path="" element={<Home />} />
    <Route path="about/" element={<About />} />
    <Route path="changelog/" element={<Changelog />} />
    <Route path="costanti/" element={<Costanti />} />
    <Route path="guidelines/" element={<LineeGuida />} />
    <Route path="last-news/" element={<UltimeModifiche />} />
    <Route path="guida/difficolta/" element={<GuidaDifficolta />} />
    <Route path="utente/" element={<ListaPartecipanti />} />
    <Route path="utente/:pk/" element={<PartecipanteDettagli />} />
    <Route path="thread/problema/:pk2/commento-edit/:pk/" element={<EditCommento />} />
    <Route path="shortlist/:pk2/commento-edit/:pk/" element={<EditCommento />} />
    <Route path="thread/problema/new/" element={<NuovoThreadProblema />} />
    <Route path="thread/problema/miei/" element={<ListaThreadProblemi />} />
    <Route path="thread/problema/assegnati/" element={<ListaThreadProblemi />} />
    <Route path="thread/problema/:pk/" element={<ThreadProblema />} />
    <Route path="thread/problema/:pk/voti/" element={<VotiThreadProblema />} />
    <Route path="thread/problema/:pk/assegna/" element={<AssegnaProblema />} />
    <Route path="thread/problema/:pk/rispondi/" element={<RispondiProblema />} />
    <Route path="thread/problema/edit/:pk/" element={<NuovoThreadProblema />} />
    <Route path="thread/problema/categoria/:pk/" element={<ListaThreadProblemi />} />
    <Route path="thread/problema/categoria-evento/:pk/" element={<ListaThreadProblemi />} />
    <Route path="thread/problema/:query?" element={<ListaThreadProblemi />} />
    <Route path="loginerror/" element={<LoginError />} />
    <Route path="accounts/profile/" element={<Profile />} />
    <Route path="drafts/:pk/" element={<EditBozza />} />
    <Route path="drafts/" element={<Bozze />} />
    <Route path="top-contributors/" element={<TopContributors />} />
    <Route path="shortlist/:pk/" element={<ShortlistDettaglio />} />
    <Route path="shortlist/new/" element={<NuovaShortlist />} />
    <Route path="shortlist/" element={<ListaShortlist />} />
    <Route path="shortlist/build/:pk/testo/" element={<OverleafShortlist />} />
    <Route path="shortlist/build/:pk/soluzioni/" element={<OverleafShortlist />} />
    <Route path="*" element={<NoMatch />} />
  </Route>
)



export const AppFactory = (creator: typeof createHashRouter, store_: typeof store) => () => {
  const router = creator(createRoutesFromElements(ROUTES))
  return (
    <Loadable load={carica}>
      <Provider store={store_}>
        <RouterProvider router={router} />
      </Provider>
    </Loadable>
  )
}
const App = AppFactory(createHashRouter, store);

export default App;


const wrapper = document.getElementById("app");
const cusu = document.getElementById("user");
const username = cusu ? cusu.textContent : "__not_logged_in";
const carica: () => Promise<plain_object> = async () => {
  const get_connection_toast = (val: string) => {
    return {
      title: "Connessione persa",
      message: "Tentativo di riconnessione in corso",
      generating_object: val,
    }
  }
  const ret = {};
  configure_drr(
    reducers_map, store,
    DEBUG,
    val => delete_old_toasts(get_connection_toast(val)),
    val => add_toast_action(get_connection_toast(val)),
  );
  if (username == "__not_logged_in") {
    return ret;
  }

  const io = await get_whoami();
  store.dispatch(set_user_data(io));
  // Controllo che esistano le notifiche. Alias: non faccio rompere Safari iOS
  try {
    Notification
  } catch (error) {
    console.log("Skippate notifiche e service worker durante il Loading");
    return ret;
  }

  if (Notification && navigator) {
    if (Notification.permission === "default") {
      try {
        await Notification.requestPermission()
      } catch (error) {
        if (error instanceof TypeError) {
          Notification.requestPermission(() => {});
        } else {
          throw error;
        }
      }
    }
    if (navigator.serviceWorker) {
      const reg = await navigator.serviceWorker.register('/static/frontend/build/service_worker.js', { scope: '/' });
      store.dispatch(create_registration(reg));
      const worker = await subscribe(reg);
      navigator.serviceWorker.addEventListener('message', service_worker_handler);
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(JSON.stringify({
          "header": "sessionid",
          "body": {
            "sessionid": get_csrf_token(),
          }
        }));
      }
    }
  }
  if (Worker) {
    let worker = new Worker("/static/frontend/build/worker_updates.js");
    const first_message = {
      type: "user_detail",
      payload: io
    }
    worker.onmessage = dedicated_worker_handler;
    worker.onerror = dedicated_worker_error_handler;
    worker.postMessage(JSON.stringify(first_message));
  }
  return ret;
}



const mapRoutesToArray = (routes: typeof ROUTES) => {
  const paths = [] as string[];
  const children = routes.props.children;
  children.map((route: typeof ROUTES.props.children[0]) => {
    const inner = route.props.path;
    if (!inner) return
    if (Array.isArray(inner)) {
      paths.concat(inner)
    } else {
      paths.push(inner as string)
    }
  })
  return paths;
}


export const generate_sitemap = () => mapRoutesToArray(ROUTES)


if (sentry_config.use_sentry) {
  Sentry.init({
    dsn: sentry_config.dsn,
    release: sentry_config.release,
  });
}


if (!TESTING && !!wrapper) {
  createRoot(wrapper).render(<App />)
}
