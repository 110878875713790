declare var __SITE_URL__: string;
declare var __DEBUG__: boolean;
declare var __SENTRY_USE_SENTRY__: boolean;
declare var __SENTRY_DSN__: string;
declare var __VERSION__: string;
declare var __THE_SENDING_MAIL__: string;
declare var __CHANGELOG__: string;
declare var __JEST_TESTING__: boolean;
declare var __DEFAULT_LOCALE__: string;


export const site_url: string = __SITE_URL__;
export const DEBUG = __DEBUG__;
export const SHOW_STORE_DEBUG = __JEST_TESTING__ ? false : DEBUG;
export const VERSION = __VERSION__;
export const PROJECT_NAME = "owlitrack";
export const THE_SENDING_MAIL = __THE_SENDING_MAIL__;
export const CHANGELOG = __CHANGELOG__;
export const DEFAULT_LOCALE = __DEFAULT_LOCALE__;
export const TESTING = __JEST_TESTING__;

// @ts-ignore
import styles from '../../../../django/owlitrack/static/owlitrack/colors.module.scss';

export { styles as themeColors };

export const sentry_config = {
  use_sentry: __SENTRY_USE_SENTRY__,
  dsn: __SENTRY_DSN__,
  release: VERSION,
}


/*
 * Per formattare in modo uniforme le date.
 */
export const date_options = {
  weekday: 'long', year: 'numeric', month: 'long',
  day: 'numeric', hour: 'numeric', minute: 'numeric',
  timeZone: "Europe/Rome"
};


export const avatar_style = {
  maxWidth: 300,
  maxHeight: 300,
}

// Quanti giorni va indietro nel tempo il feed in UltimeModifiche
export const GIORNI_FEED = 7;

// Questo deve essere sincronizzato con problems/models.py:DECIMAL_PLACES
export const DECIMAL_PLACES = 0.00001;
export const DECIMAL_DIGITS = 5;


// Returns true if we need the real value, false if we need a dummy replacement
const dummy = () => {
  if (__JEST_TESTING__) return false;
  if (typeof document === 'undefined') return false;
  return true;
}


const testing_or_dummy = (name: string) => dummy() ? document.getElementById(name)?.textContent || undefined : name

export const GOOGLE_URL = testing_or_dummy("social-google");
export const pdf_gimmy = testing_or_dummy("howto-write-problem-gimmy");
export const login_url = (redir?: string) => {
  const trgt = !!redir ? redir : window.location.hash.replace("#", "%23");
  window.location.assign(`${window.location.origin}${GOOGLE_URL}?next=/${trgt}`)
}

export const image_placeholder_url = "/static/owlitrack/images/profile-placeholder.jpg"

export * from './display';
export * from './permessi';
export * from './style';
export const correzioni_richieste = 3;


export const ORDINE_SHORTLIST = [
  { id: 'ordine', display: 'In ordine shortlist' },
  { id: 'difficolta', display: 'Difficoltà' },
  { id: 'bellezza', display: 'Bellezza' },
]
