import React, { Component, useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";

import { ThreadLista, ThreadShortlist, } from '../api/types';
import { displayUser, displayBadgePermesso } from '../utils';
import { avatar_style, login_url } from '../globals';
import { CategoriaMap, EventoMap, } from '../components';
import { useCategoria, useEvento, useLogin, usePartecipante, useShortlist, useThread } from '../reducers';
import { emptyFeed, fetchFeed, renderFeed } from '../utils/feed';


const PartecipanteDettagli = (props: {}) => {

  const partecipanti = usePartecipante(props);
  const thread = useThread(props);
  const eventi = useEvento(props);
  const categorie = useCategoria(props);
  const shortlist = useShortlist(props);
  const login = useLogin();
  const params = useParams<{ pk: string }>();
  const [feed, setFeed] = useState(emptyFeed);


  const pk = parseInt(params.pk as string);
  const detail = partecipanti.find(item => item.id == pk);

  useEffect(() => {

    fetchFeed({
      commenti: { partecipante: pk },
      commenti_shortlist: { partecipante: pk },
      risposte: { partecipante: pk },
      assegnazione: { partecipante: pk },
      modifiche_commenti: { partecipante: pk },
      modifiche_problemi: { autore: pk },
      citazioni: { partecipante: pk },
    }).then(setFeed);
  }, [pk])

  useEffect(() => {
    if (!!detail) {
      document.title = `${displayUser(detail?.user)} - Owlitrack`
    }
  }, [detail])

  if (!login.logged_in) {
    login_url()
  }

  if (!detail) {
    return (
      <div className="container">
        <h1 className="page-header">Not found</h1>
        <p>Nessun utente con indice {pk} trovato.</p>
      </div>
    );
  }

  const rendered_feed = renderFeed(feed, thread, prob => prob.autore === pk, false, true);

  return (
    <div className="container">
      <h1 className="page-header">{"Dettagli dell'utente " + displayUser(detail.user)}</h1>
      <div className={"d-flex align-items-center justify-content-center " +
        "justify-content-sm-between flex-sm-row flex-column px-2"}>
        <div>
          <p>Permessi: {displayBadgePermesso(detail.permessi)}</p>
          <p>Categorie preferite: <CategoriaMap categorie={detail.categorie_preferite} /></p>
          <p>Eventi assegnati: <EventoMap eventi={detail.eventi_assegnati} /></p>
        </div>
        <div>
          {detail.avatar &&
            <img src={`/avatars/${detail.avatar}/`}
              alt={displayUser(detail.user)} style={avatar_style}
              className="img-thumbnail rounded"
            />}
        </div>
      </div>
      <h3>Thread creati</h3>
      <ul>
        {detail.thread_creati.map(item => {
          let giusto = thread.find(i => i.id == item) as ThreadLista | ThreadShortlist;
          if (!giusto) {
            const try2 = shortlist.find(i => i.id == item);
            if (!try2) {
              return <li key={item}>
                <Link to={`/thread/problema/${item}/`}>
                  Non disponibile
                </Link>
              </li>
            }
            giusto = try2;
          }
          // @ts-ignore
          const link = (giusto.hasOwnProperty('tipologia') && giusto.tipologia == 's') ?
            `/shortlist/${item}/`
            : `/thread/problema/${item}/`;
          return <li key={item}>
            <Link to={link}>
              {giusto.titolo}
            </Link>
          </li>
        })}
      </ul>
      <h3>Problemi assegnati</h3>
      <ul>
        {thread.filter(
          item => {
            // @ts-ignore
            return item.problema.correttori_assegnati.includes(parseInt(detail.id))
          }).map(item => {
            // @ts-ignore
            const tick = item.problema.correttori_eseguito.includes(parseInt(detail.id)) ?
              <span className="mr-1"><i className="fa fa-check text-success" /></span> :
              null;
            return <li key={item.id}>
              <Link to={`/thread/problema/${item.id}/`}>
                {tick}{item.titolo}
              </Link>
            </li>
          })}
      </ul>
      <h3>Thread commentati</h3>
      <ul>
        {detail.thread_commentati
          .filter((v, i, a) => a.indexOf(v) === i)
          .map(item => {
            const giusto = thread.find(i => i.id == item);
            if (!giusto) {
              return <li key={item}>
                <Link to={`/thread/problema/${item}/`}>
                  Non disponibile
                </Link>
              </li>
            }
            return <li key={item}>
              <Link to={`/thread/problema/${item}/`}>
                {giusto.titolo}
              </Link>
            </li>
          })}
      </ul>
      <h3>Newsfeed</h3>
      <div className="container mb-4">
        {rendered_feed.reverse()}
      </div>
    </div >
  );
}

export default PartecipanteDettagli;
