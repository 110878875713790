import React, { FormEvent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { clone } from 'lodash';

import { PermissionDenied } from 'django-rest-react';
import { get_problema_thread, assegna_problemi } from '../api';
import {
  Problema, LoadingCircle, CategoriaMap,
  FormGroupBootstrap, ErrorBlockGeneric,
  ConfirmedButton
} from '../components';
import { Partecipante, Categoria } from '../api/types';
import {
  displayPartecipanteBadge, displayPartecipante, displayCategoria, sentry_log
} from '../utils';
import { canAssegnare, login_url } from '../globals'
import { useCategoria, useLogin, usePartecipante } from '../reducers';

interface FormValues {
  categorie: number[],
}

type Thread = Awaited<ReturnType<typeof get_problema_thread>>


const AssegnaProblema = (props: {}) => {
  const login = useLogin();
  const partecipanti = usePartecipante(props);
  const categorie = useCategoria(props);

  const [thread, setThread] = useState<Thread | null>(null);
  const [listaFiltrata, setListaFiltrata] = useState<Partecipante[]>([])
  const [error, setError] = useState<string | null>(null)
  const [personeAssegnate, setPersoneAssegnate] = useState(new Set<number>())
  const [order, setOrder] = useState(1 as 1 | -1)
  const navigate = useNavigate()
  const params = useParams<{ pk: string }>()



  const pk = parseInt(params.pk as string);
  useEffect(() => {
    document.title = `Assegna #${pk} - Owlitrack`
    get_problema_thread(pk).then(thread => {
      setThread(thread)
      setPersoneAssegnate(new Set(thread.problema.correttori_assegnati))
    });
  }, [pk])


  if (!login.logged_in) {
    login_url(window.location.hash);
  }
  if (!canAssegnare(login.user)) {
    throw new PermissionDenied({ message: "Devi essere almeno smistatore per assegnare problemi. " })
  }
  if (!thread) {
    return (
      <LoadingCircle />
    );
  }


  const sort = (sortparam: (i: Partecipante) => number | string) => {
    const nuovo = Object.assign([], listaFiltrata);
    if (typeof sortparam(nuovo[0]) == "number") {
      nuovo.sort((a, b) => (order * ((sortparam(a) as number) - (sortparam(b) as number))));
    } else {
      const func = (a: Partecipante, b: Partecipante) => {
        let cusu = 1;
        if (sortparam(a) < sortparam(b)) {
          cusu = -1
        }
        return order * cusu;
      }
      nuovo.sort((a, b) => func(a, b));
    }
    setListaFiltrata(nuovo)
    setOrder(val => (val * -1) as 1 | -1)
  }

  const filtra = (values: FormValues, actions: FormikActions<FormValues>) => {
    let nuovo = partecipanti;
    if (values.categorie.length > 0) {
      for (const c of values.categorie) {
        // @ts-ignore
        nuovo = nuovo.filter(item => item.categorie_preferite.includes(parseInt(c)));
      }
    }
    actions.setSubmitting(false);
    setListaFiltrata(nuovo)
  }

  if (listaFiltrata.length == 0 && partecipanti.length > 0) {
    setListaFiltrata(partecipanti);
  }

  return (
    <div className="container-fluid">
      <h1>Assegna un problema</h1>
      <ErrorBlockGeneric error={error} />
      <Problema thread={thread} />
      <br />
      <h3>Filtra le persone per categoria preferita</h3>
      <Formik
        initialValues={{
          categorie: [],
        }}
        onSubmit={filtra}
      >
        {({ isSubmitting, handleReset }) => {
          return (
            <Form>
              <FormGroupBootstrap
                name="categorie" type="select-multiple"
                choices={categorie}
                displayChoice={(item: Categoria) => displayCategoria(item.id)}
              />
              <div className="d-flex justify-content-end">
                <ConfirmedButton
                  type="warning"
                  onSubmit={handleReset}>
                  Reset
                </ConfirmedButton>
                <button type="submit" disabled={isSubmitting}
                  className="mx-1 btn btn-primary">Filtra</button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <br />
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-striped">
          <thead className="thead-dark">
            <tr>
              <th onClick={() => sort((item) => displayPartecipante(item.id))}>
                <i className="fa fa-sort" /> Nome</th>
              <th onClick={() => sort((item) => item.quanti_assegnati)}>
                <i className="fa fa-sort" /> Assegnati</th>
              <th onClick={() => sort((item) => item.quanti_corretti)}>
                <i className="fa fa-sort" /> Risolti</th>
              <th onClick={() => sort((item) => (item.quanti_assegnati - item.quanti_assegnati_fatti))}>
                <i className="fa fa-sort" />Da fare</th>
              <th>Categorie</th>
              <th>Assegna</th>
            </tr>
          </thead>
          <tbody>
            {listaFiltrata.map(item => {
              const old_checked = personeAssegnate.has(item.id)
              return (
                <tr key={item.id}>
                  <td>{displayPartecipanteBadge(item)}</td>
                  <td>{item.quanti_assegnati}</td>
                  <td>{item.quanti_corretti}</td>
                  <td>{item.quanti_assegnati - item.quanti_assegnati_fatti}</td>
                  <td><CategoriaMap categorie={item.categorie_preferite} /></td>
                  <td>
                    <input
                      type="checkbox" checked={old_checked}
                      onChange={(e) => {
                        const { checked } = e.target;
                        const nuovi_assegnati = clone(personeAssegnate);
                        if (checked) {
                          nuovi_assegnati.add(item.id);
                        } else {
                          nuovi_assegnati.delete(item.id);
                        }
                        setPersoneAssegnate(nuovi_assegnati)
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <button type="reset" onClick={() => setPersoneAssegnate(new Set(thread.problema.correttori_assegnati))}
          className="mx-1 btn btn-warning">Reset</button>
        <button type="submit" onClick={(e: FormEvent) => {
          e.preventDefault()
          const submitting_data = {
            correttori_assegnati: Array.from(personeAssegnate),
          }
          assegna_problemi(thread.problema.id, submitting_data)
            .then(() => navigate(`/thread/problema/${thread.id}/`))
            .catch(error => {
              sentry_log(error);
              setError(error);
            });
        }}
          className="mx-1 btn btn-primary">Invia</button>
      </div>
    </div >
  );
}


export default AssegnaProblema;
