import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import { nameToEmoji } from 'gemoji'

import XRegExp from 'xregexp';

import { Spoiler } from '../components'
import { displayPartecipante } from '../utils';
import { useEvento, usePartecipante, useThread } from '../reducers'
import { parse_latex } from 'django-rest-react'


interface ownProps {
  source: string
  hideSpoiler?: boolean
}


export const splitSpoiler: (source: string, hideSpoiler: boolean) => string[] = (source, hideSpoiler) => {
  if (!source) return [];
  const result = hideSpoiler ? source.replace(/\[spoiler\]/g, "").replace(/\[\/spoiler\]/, "") : source;
  const spoilers = XRegExp.matchRecursive(result, "\\[spoiler\\]", "\\[/spoiler\\]", "g", { unbalanced: "skip" });
  if (spoilers.length == 0) {
    return [source]
  }
  const spoilcontent = spoilers[0];
  const spoilwithdelim = `[spoiler]${spoilcontent}[/spoiler]`
  const splitted = XRegExp.split(result, spoilwithdelim, 2)
  return [splitted[0], spoilcontent, ...splitSpoiler(splitted[1], hideSpoiler)]

}

const MdMath = (props: ownProps) => {

  const partecipanti = usePartecipante(props);
  const threads = useThread(props);
  const eventi = useEvento(props);

  const { source } = props;
  if (!source) {
    return <></>
  }
  // split content in spoilers and not
  const chain = splitSpoiler(source, !!props.hideSpoiler);

  if (chain.length > 1) {
    return <>
      {chain.map((content, idx) => {
        if (idx % 2 == 1) {
          return <Spoiler key={idx}><MdMath source={content} /> </Spoiler>
        } else {
          return <MdMath key={idx} source={content} />
        }
      })}
    </>
  }
  // Render emoji
  const rendered = parse_latex(source.replace(/:(\+1|[-\w]+):/g, (_, group) => nameToEmoji[group]));

  const text = rendered
    .replace(/#([0-9]+)/g, (matched) => {
      const thread = threads.find(item => item.id == parseInt(matched.substring(1)));
      if (thread) {
        return `[#${thread.id} - ${thread.titolo}](/#/thread/problema/${thread.id}/)`;
      } else {
        return "_Thread non disponibile._";
      }
    })
    .replace(/@([a-zA-Z_0-9\-\.]+)/g, (matched) => {
      const part = partecipanti.find(item => item.user.username == matched.substring(1));
      if (part) {
        return `[${displayPartecipante(part.id)}](/#/utente/${part.id}/)`;
      } else {
        return "_Partecipante non trovato._";
      }
    })
    .replace(/!([0-9]+)/g, (matched) => {
      const ev = eventi.find(item => item.id == parseInt(matched.substring(1)));
      if (ev) {
        return `[${ev.titolo}](/#/thread/problema/evento/${ev.id}/)`;
      } else {
        return "_Evento non trovato._"
      }
    });


  const plugins = [
    remarkMath,
    remarkGfm,
  ]
  const hypePlugins = [
    rehypeMathjax,
  ]
  return (
    <ReactMarkdown
      remarkPlugins={plugins}
      rehypePlugins={hypePlugins}
    >
      {text}
    </ReactMarkdown>
  )
}

export default MdMath;
