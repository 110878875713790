import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { cloneDeep } from 'lodash';

import { Categoria, Evento, ThreadLista, CategoriaEvento } from '../api/types';
import { search_problemi_string } from '../api'
import { canSeeVoti, login_url } from '../globals';
import {
  displayPartecipante, nice_date, displayEvento, displayCategoria,
} from '../utils';
import {
  Spoiler, FormGroupBootstrap, ConfirmedButton, ProblemaTabella
} from '../components';
import { useCategoriaEvento, useLogin, useEvento, useCategoria, usePartecipante, useThread } from '../reducers';


export type SortFunc = (thr: ThreadLista) => number
const pageMatch = useParams<{ pk: string, query?: string }>;

export const empty_initial_values: FormValues = {
  categorie: [], eventi: [], solo_non_assegnati: true, mostra_scartati_duplicati: false,
  categorieevento: [], ricerca_diretta: "",
}

export interface FormValues {
  categorie: string[],
  eventi: string[],
  categorieevento: string[],
  solo_non_assegnati: boolean,
  mostra_scartati_duplicati: boolean,
  ricerca_diretta: string,
}

type FilterPipeline = (prob: ThreadLista) => boolean;

const get_pipeline = (login: ReturnType<typeof useLogin>, params: ReturnType<typeof pageMatch>, location: ReturnType<typeof useLocation>, values: FormValues) => {
  const pipeline: FilterPipeline[] = [];

  let categorie; let categorieevento: number[];
  const eventi = values.eventi.map(val => parseInt(val));

  // Categorie
  if (location.pathname.match(/thread\/problema\/categoria\//g)) {
    categorie = [parseInt(params.pk as string),]
  } else {
    categorie = values.categorie.map(val => parseInt(val));
  }

  // Categoria evento
  if (location.pathname.match(/thread\/problema\/categoria-evento/g)) {
    categorieevento = [parseInt(params.pk as string),]
  } else {
    categorieevento = values.categorieevento.map(val => parseInt(val));
  }

  // Assegnati a me
  if (location.pathname.match(/thread\/problema\/assegnati/g)) {
    pipeline.push(thr => thr.problema.correttori_assegnati.includes(login.user.id));
  }

  // Proposti da me
  if (location.pathname.match(/thread\/problema\/miei/g)) {
    pipeline.push(thr => thr.problema.propositore == login.user.id)
  }

  for (const c of categorie) {
    pipeline.push(thr => thr.problema.categoria.includes(c))
  }
  if (eventi.length > 0) {
    pipeline.push(thr => eventi.filter(ev => thr.problema.evento_assegnato == ev).length > 0)
  }
  if (categorieevento.length > 0) {
    pipeline.push(thr => categorieevento.filter(ev => thr.problema.eventi_proposto.includes(ev)).length > 0)
  }
  if (values.solo_non_assegnati) {
    pipeline.push(thr => thr.problema.evento_assegnato === null)
  }
  if (!values.mostra_scartati_duplicati) {
    pipeline.push(thread => {
      if (!thread.problema.flags_smistatore) return true;
      const { flag } = thread.problema.flags_smistatore;
      return flag != "d" && flag != "s"
    })
  }
  return pipeline;
}

export const filtra = (login: ReturnType<typeof useLogin>, params: ReturnType<typeof pageMatch>, location: ReturnType<typeof useLocation>, values: FormValues, lista: ThreadLista[], sortFun: SortFunc, order: number, pkList: number[]) => {
  const pipeline = get_pipeline(login, params, location, values);
  if (pkList.length > 0) {
    pipeline.push(thr => pkList.includes(thr.problema.id))
  }

  let nuovo = lista;
  for (const elem of pipeline) {
    nuovo = nuovo.filter(elem);
  }
  nuovo.sort((a, b) => (order * (sortFun(a) - sortFun(b))));
  return nuovo;
}


const ListaThreadProblemi = (props: {}) => {
  let initial_values = cloneDeep(empty_initial_values)
  const location = useLocation();
  const params = pageMatch()
  if (location.pathname.match(/thread\/problema\/categoria\//g)) {
    initial_values.categorie = [params.pk as string,]
  }
  if (location.pathname.match(/thread\/problema\/categoria-evento/g)) {
    initial_values.categorieevento = [params.pk as string,]
  }

  const [difficolta_aperto, setDifficoltaAperto] = useState(false);
  const [bellezza_aperto, setBellezzaAperto] = useState(false);
  const [order, setOrder] = useState(-1);
  const [sortParam, setSortParam] = useState(() => (thr => thr.id) as SortFunc)
  const [filterValues, setFilterValues] = useState(initial_values)
  const [searchPkList, setSearchPkList] = useState<number[]>([])


  const login = useLogin();
  const categorie = useCategoria(props);
  const categorieevento = useCategoriaEvento(props);
  const eventi = useEvento(props);
  const thread = useThread(props);
  usePartecipante(props);

  useEffect(() => {
    document.title = "Problemi - Owlitrack";
  }, [])

  if (!login.logged_in) {
    login_url()
  }
  const changeSorting = (fun: SortFunc) => {
    setOrder(ord => -1 * ord);
    setSortParam(() => fun);
  }
  const lista_filtrata = filtra(login, props, location, filterValues, thread, sortParam, order, searchPkList);

  return (
    <div className="container">
      <Link to={`/thread/problema/new/`}><button className="btn btn-primary">Nuovo problema</button></Link>
      <h1>Lista delle discussioni</h1>
      <h3>Filtra le discussioni</h3>
      <Formik
        initialValues={initial_values}
        onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
          setFilterValues(values);
          if (values.ricerca_diretta != "") {
            search_problemi_string(values.ricerca_diretta).then(results => results.map(val => val[0])).then(setSearchPkList)
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleReset }) => {
          return (
            <>
              <Form className="form needs-validation">
                <FormGroupBootstrap
                  name="categorie" type="select-multiple"
                  choices={categorie}
                  displayChoice={(item: Categoria) => displayCategoria(item.id)}
                />
                <FormGroupBootstrap
                  name="eventi" type="select-multiple"
                  displayName="Evento assegnato"
                  choices={eventi}
                  displayChoice={(item: Evento) => displayEvento(item.id)}
                  help_text="Questo è uno dei due filtri inteso come OR. Se viene selezionato più di un evento, vengono selezionati i problemi assegnati per il primo evento OPPURE per il secondo, e via dicendo."
                />
                <FormGroupBootstrap
                  name="categorieevento" type="select-multiple"
                  choices={categorieevento}
                  displayChoice={(item: CategoriaEvento) => item.nome}
                  displayName="Categoria evento"
                  help_text="Anche questo è inteso come OR"
                />
                <FormGroupBootstrap
                  name="solo_non_assegnati" type="checkbox"
                  displayName="Solo non assegnati"
                  help_text="Solo problemi non ancora utilizzati in un evento."
                />
                <FormGroupBootstrap
                  name="mostra_scartati_duplicati" type="checkbox" help_text="Mostra anche i problemi marchiati come duplicati o scartati."
                />
                <FormGroupBootstrap
                  name="ricerca_diretta" type="text" help_text="Ricerca per testo"
                />
                <div className="d-flex justify-content-end">
                  <ConfirmedButton
                    type="warning"
                    onSubmit={handleReset}>
                    Reset
                  </ConfirmedButton>
                  <button type="submit" disabled={isSubmitting}
                    className="mx-1 btn btn-primary">Filtra</button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <h3>Tabella</h3>
      <h5>Totale problemi trovati corrispondenti ai filtri: {lista_filtrata.length}</h5>
      {canSeeVoti(login.user.permessi) &&
        <div className="d-flex col-sm my-1">
          <button
            className="btn mr-auto btn-secondary" role="button"
            onClick={() => setDifficoltaAperto(val => !val)}
          >
            Toggle difficoltá
          </button>
          <button
            className="btn ml-auto btn-secondary" role="button"
            onClick={() => setBellezzaAperto(val => !val)}
          >
            Toggle bellezza
          </button>
        </div>
      }
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-striped">
          <thead className="thead-dark">
            <tr>
              <th title="Ordina per numero" onClick={() => changeSorting(thr => thr.id)}>
                <i className="fa fa-sort" /> Titolo</th>
              {canSeeVoti(login.user.permessi) &&
                <>
                  <th onClick={() => changeSorting(thr => thr.bellezza_medio)}>
                    <i className="fa fa-sort" /> Bellezza
                  </th>
                  <th onClick={() => changeSorting(thr => thr.difficolta_medio)}>
                    <i className="fa fa-sort" /> Difficoltà
                  </th>
                </>
              }
              <th title="Persone che devono correggere il problema"
                onClick={() => changeSorting(thr => thr.problema.correttori_assegnati.length)}>
                <i className="fa fa-sort" />Ass
              </th>
              <th title="Correzioni"
                onClick={() => changeSorting(thr => thr.problema.correttori_eseguito.length)}>
                <i className="fa fa-sort" /> Corr
              </th>
              <th title="Giuste/Sbagliate">Risp</th>
              <th title="Visualizzazioni"
                onClick={() => changeSorting(thr => thr.visualizzazioni)}>
                <i className="fa fa-sort" /> Views
              </th>
              <th title="Proposto da">Proposto da</th>
              <th title="Ultima modifica"
                onClick={() => changeSorting(thr => new Date(thr.ultima_modifica).getTime())}
              ><i className="fa fa-sort" /> Last</th>
            </tr>
          </thead>
          <tbody>
            {lista_filtrata.map((item) => {
              const { id } = login.user;
              const eseguito = item.problema.correttori_eseguito.includes(id);
              const bellezza_medio = item.bellezza_medio ? item.bellezza_medio.toFixed(2) : item.bellezza_medio;
              const difficolta_medio = item.difficolta_medio ? item.difficolta_medio.toFixed(2) : item.difficolta_medio;
              const difficolta_auto = item.problema?.difficolta_automatica.toFixed(2);
              const diff = `${difficolta_medio} (${difficolta_auto})`
              const bellezza = bellezza_aperto ?
                bellezza_medio :
                <Spoiler>
                  {bellezza_medio}
                </Spoiler>;
              const difficolta = difficolta_aperto ?
                diff :
                <Spoiler>
                  {diff}
                </Spoiler>;
              return (
                <tr key={item.id}>
                  <td>
                    <ProblemaTabella item={item} />
                  </td>
                  {canSeeVoti(login.user.permessi) &&
                    <>
                      <td>{bellezza}</td>
                      <td>{difficolta}</td>
                    </>
                  }
                  <td>
                    {item.problema.correttori_assegnati.length}
                  </td>
                  <td>
                    {item.problema.correttori_eseguito.length}
                    {eseguito &&
                      <i className="text-success fa fa-check" />
                    }
                  </td>
                  <td>
                    <span title="Giusto/Sbagliato">
                      {item.risposto_giusto}/{item.risposto_totale - item.risposto_giusto}
                    </span>
                  </td>
                  <td>{item.visualizzazioni}</td>
                  <td>
                    <Link to={`/utente/${item.autore}/`}>
                      {displayPartecipante(item.autore)}
                      <br />
                      <small>
                        {nice_date(item.data_inizio)}
                      </small>
                    </Link>
                  </td>
                  <td>
                    <small>{nice_date(new Date(item.ultima_modifica))}</small>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListaThreadProblemi;
