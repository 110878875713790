import React, { Component, useRef, useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers as FormikActions, FormikErrors, FormikValues, useFormikContext, getIn } from 'formik';
import { Popover, Overlay, Button } from 'react-bootstrap'
import { cloneDeep } from 'lodash'

import { handle_drf_form_error } from 'django-rest-react'
import { FormGroupBootstrap, ConfirmedButton, ErrorBlockGeneric } from '../components'
import { get_allegati, refresh_allegato, upload_allegato } from '../api';
import store from '../store';


interface IProps {
  setAllegati: (newpk: number) => void
  allegatiStr: string
}

type FormValuesAllegato = Parameters<typeof upload_allegato>[0]
type Allegato = Awaited<ReturnType<typeof get_allegati>>[0]

interface FormValues {
  scelta?: number,
  _file?: any,
  titolo?: string,
}

export default function PubblicaAllegato(props: IProps) {

  const [show, setShow] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [allegati, setAllegati] = useState<Allegato[]>([]);


  const validate = (values: FormValues) => {
    const errors = {};
    if (!(values.titolo || values.scelta)) {
      // @ts-ignore
      errors.titolo = "Il titolo è richiesto!"
    }
    return errors;
  }

  const formik = useFormikContext();
  const oldall = cloneDeep(getIn(formik.values, props.allegatiStr))

  const target = useRef(null);
  useEffect(() => {
    get_allegati({ partecipante: store.getState().login.user.id }).then(setAllegati);
  }, []);

  const addPk = (newpk: number) => {
    if (!oldall.includes(newpk)) {
      oldall.push(newpk)
      props.setAllegati(oldall)
    }
    setShow(false);
  }

  const onSubmit = (values: FormValues, actions: FormikActions<FormValues>) => {
    if (values.scelta) {
      addPk(values.scelta)
      return;
    }
    upload_allegato(values as FormValuesAllegato)
      .then(nuovo => refresh_allegato().then(async () => {
        actions.resetForm()
        addPk(nuovo.id)
        return get_allegati({ partecipante: store.getState().login.user.id }).then(setAllegati)
      }))
      .catch(err => handle_drf_form_error(err, actions, s => setError(s)))
  }

  return (
    <>
      <Button
        className="btn btn-success" title="Allegati"
        onClick={() => setShow(!show)}
        ref={target}>
        <i className="fa fa-file" /> Allega un file
      </Button>
      <Overlay
        placement="top"
        show={show}
        target={target.current}
      >
        {({ ...old }: any) => {
          return (
            <Popover id={`allegati`} {...old}>
              <Popover.Title>
                <span className="mr-2">
                  <i className="fa fa-times text-muted"
                    onClick={() => setShow(false)} />
                </span>
                Allega un file
              </Popover.Title>
              <Popover.Content>
                <Formik
                  initialValues={{ titolo: '', _file: null, scelta: undefined }}
                  onSubmit={onSubmit}
                  validate={validate}
                >
                  {({ isSubmitting, setFieldValue, handleReset, values }) => {
                    return (
                      <div className="container-fluid">
                        <Form className="form needs-validation">
                          <FormGroupBootstrap name="titolo" type="text" />
                          <FormGroupBootstrap
                            name="_file" type="file"
                            displayName="File"
                          />
                          <FormGroupBootstrap
                            name="scelta" type="select"
                            choices={allegati}
                            displayChoice={(item: Allegato) => item.titolo}
                            help_text={"In alternativa ad un upload puoi scegliere" +
                              " un allegato caricato precedentemente."}
                          />
                          <div className="d-flex justify-content-end">
                            <ConfirmedButton
                              type="warning"
                              onSubmit={() => {
                                handleReset();
                                setFieldValue("scelta", 0)
                                setFieldValue("_file", null)
                              }}>
                              Reset
                            </ConfirmedButton>
                            <button type="submit" disabled={isSubmitting}
                              className="mx-1 btn btn-primary">Invia</button>
                          </div>
                          <ErrorBlockGeneric error={error} />
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
    </>
  );
}
