export type {
  User, Evento, Allegato, BozzaProblema,
  Problema, VersioneShortlist, Shortlist,
  FlagSmistatore, Partecipante, Commento, Categoria,
  ProblemaVersionatoFeed,
  RispostaConRisposta as Risposta,
  SoluzioneConRisposta as Soluzione,
  CommentoVersionatoFeed as CommentoVersioneFeed,
  CommentoVersionato as CommentoVersione,
  VotoBellezza as Voto,
  AssegnazioneProblema as Assegnazione,
  AssegnazioneProblemaEvento as AssegnazioneEvento,
  Whoami as UserWhoami,
  ProblemaVersionato as ProblemaVersione,
  PartecipanteContributor as Contributor, CitazioneThread,
  Linter, CategoriaEvento, BozzaProblemaForm as BozzaProblemaData,
  ThreadProblemGenericForm, ThreadProblemGenericConRisposta,
  BuildShortlistForm,
} from './auto-types'
export { WithId } from 'django-rest-react'
import {
  Problema, Partecipante, FlagSmistatore, VersioneShortlist,
  ThreadShortlist as ThreadShortlistOrig, ThreadProblemGenericConRisposta,
  ThreadProblemDetailConRisposta,
} from './auto-types'
import { get_voto_difficolta } from './auto-apis'


type SoluzioneWrite = "label" | "udm" | "analitica" | "soluzione" | "soluzione_exp" | "precisione"
export const SOLUZIONE_KEYS: SoluzioneWrite[] = [
  "label", "soluzione", "soluzione_exp", "udm", "analitica", "precisione",
]

export type Permessi = Partecipante["permessi"]
export type FlagSmistatoreChar = FlagSmistatore["flag"]
export type StatoShortlist = VersioneShortlist["stato"]
export type Fonte = Problema["fonte"]

export interface ThreadShortlist extends ThreadShortlistOrig {
  shortlist: NonNullable<ThreadShortlistOrig["shortlist"]>
}
export interface ThreadLista extends ThreadProblemGenericConRisposta {
  problema: NonNullable<ThreadProblemGenericConRisposta["problema"]>
}
export interface ThreadDettaglio extends ThreadProblemDetailConRisposta {
  problema: NonNullable<ThreadProblemDetailConRisposta["problema"]>
}

export type FiltroVoto = Parameters<typeof get_voto_difficolta>[0]

export interface FiltroProblemaThread {
  ass?: 'y',
  miei?: 'y',
  cat?: number
}
