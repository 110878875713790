import React, { useState } from 'react';


const Spoiler = (props: { children: React.ReactNode, big?: boolean }) => {

  const [show, setShow] = useState(false);

  const spoilerShow = (big: boolean) => {
    let content = big ?
      <div className="w-100" onClick={onClick}>{props.children}</div> :
      <span className='border border-warning px-1 py-1' style={{ display: 'inline-block' }}>
        {props.children}
      </span>

    return (
      <React.Fragment>
        <div>
          <p className="text-warning mb-0">
            <small>Chiudi Spoiler</small>
          </p>
          {content}
        </div>
      </React.Fragment>
    );
  }


  const inside = show ?
    spoilerShow(!!props.big) :
    <span className="badge badge-warning">Spoiler</span>;

  const onClick = () => setShow(val => !val);

  return (
    <span onClick={onClick}>{inside}</span>
  );

}
export default Spoiler;
