import React, { useEffect } from 'react';
import { useParams } from 'react-router'

import { ModificaBozza } from '../components';
import { login_url } from '../globals'
import { useLogin } from '../reducers'


const EditBozza = () => {
  const login = useLogin();
  const params = useParams<{ pk: string }>();
  useEffect(() => {
    document.title = `Modifica #${params.pk} - Owlitrack`;
  }, [])
  if (!login.logged_in) {
    login_url()
  }
  return (
    <div className="container">
      <h1 className="page-header">Modifica una bozza</h1>
      <ModificaBozza pk={parseInt(params.pk as string)} />
    </div>
  );
}

export default EditBozza;
