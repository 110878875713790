import React, { useState } from 'react';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { cloneDeep } from 'lodash';

import { FormGroupBootstrap, ErrorBlockGeneric, ConfirmedButton } from '../components'
import { FLAGS_SMISTATORE } from '../globals';
import { ThreadDettaglio, ThreadLista, Problema } from '../api/types';
import { post_flags, patch_flags } from '../api';
import { APIError, handle_drf_form_error } from 'django-rest-react';


interface ownProps {
  thread: ThreadDettaglio,
  thread_list: ThreadLista[]
}

interface ProblemaBack extends Problema {
  thread_detail: ThreadLista
}

type FormValues = Parameters<typeof post_flags>[0]


const FormFlagSmistatore = (props: ownProps) => {
  const { thread, thread_list } = props;
  const [error, setError] = useState<string | null>(null);

  let initial = cloneDeep(thread.problema.flags_smistatore) as FormValues;
  const modifica = !!initial;
  const empty_flags_smistatore: FormValues = {
    messaggio_extra: "",
    flag: "b",
    problemi_simili: [] as number[],
    problema: thread.problema.id
  }

  if (initial === null) {
    initial = empty_flags_smistatore;
  }
  for (const prop in initial) {
    const tprop = prop as keyof FormValues;
    if (initial.hasOwnProperty(prop) && initial[tprop] === null) {
      // @ts-ignore
      initial[tprop] = undefined;
    }
  }

  return (
    <>
      <ErrorBlockGeneric error={error} />
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
          values.problema = thread.problema.id;
          const call_function = modifica ?
            () => patch_flags(thread.problema.id, values) :
            () => post_flags(values);
          call_function()
            .then(() => window.location.reload())
            .catch((error: APIError) => handle_drf_form_error(error, actions, setError))
            .finally(() => actions.setSubmitting(false))
        }}
      >
        {({ isSubmitting, handleReset }) => {
          return (
            <Form className="form needs-validation">
              <FormGroupBootstrap
                name="flag" type="select"
                choices={FLAGS_SMISTATORE}
              />
              <FormGroupBootstrap
                name="messaggio_extra" type="text"
              />
              <FormGroupBootstrap
                name="problemi_simili" type="select-multiple"
                choices={thread_list.map(item => {
                  const retval: ProblemaBack = {
                    ...item.problema,
                    thread_detail: item,
                  }
                  return retval;
                })}
                displayChoice={(item: ProblemaBack) => `#${item.thread_detail.id} - ${item.titolo}`}
              />
              <ConfirmedButton
                type="warning"
                onSubmit={handleReset}>
                Reset
              </ConfirmedButton>
              <button type="submit" disabled={isSubmitting}
                className="mx-1 btn btn-primary">
                Invia
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}


export default FormFlagSmistatore;
