import React, { Component, ReactElement, useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Formik, Form, FormikHelpers as FormikActions, FormikErrors, FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';

import { handle_drf_form_error } from 'django-rest-react';
import {
  FormGroupBootstrap, Anteprima, ErrorBlockGeneric, LoadingCircle, PubblicaAllegato, AllegatoMap,
  TextHelper, ConfirmedButton
} from '../components'

import { APIError } from 'django-rest-react';
import {
  post_new_commento, get_commento_detail, patch_commento,
  post_new_commento_shortlist, patch_commento_shortlist,
  refresh_shortlist, get_commento_shortlist_detail
} from '../api';
import { deserialize_versioned } from '../utils'
import { useAllegato } from '../reducers';

interface FormValues {
  allegati: number[],
  testo: string
}

interface ownProps {
  thread: number
  old?: number,
  shortlist?: boolean,
};


type Old = Awaited<ReturnType<typeof get_commento_detail>>

const NuovoCommento = (props: ownProps) => {

  const prold = props.old;
  const allegati = useAllegato(props);

  const [error, setError] = useState<string | null>(null);
  const textarea = useRef(null);
  const [stold, setStold] = useState<Old | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (prold) {
      let get_func = props.shortlist ? get_commento_shortlist_detail : get_commento_detail;
      get_func(prold).then(setStold);
    }
  }, [prold])


  if (prold && !stold) {
    return (
      <LoadingCircle />
    )
  }

  const smistaSuccessivo = (submitting_data: any) => {
    let call_function;
    if (!props.shortlist) {
      call_function = prold ?
        () => patch_commento(prold, submitting_data) :
        () => post_new_commento(submitting_data);

    } else {
      call_function = prold ?
        () => patch_commento_shortlist(prold, submitting_data) :
        () => post_new_commento_shortlist(submitting_data);
    }
    let then_function;
    if (!props.shortlist) {
      then_function = prold ?
        () => navigate(`/thread/problema/${props.thread}/`) :
        () => window.location.reload();
    } else {
      then_function = prold ?
        () => {
          refresh_shortlist();
          navigate(`/shortlist/${props.thread}/`)
        } :
        () => refresh_shortlist();
    }
    return {
      call_function: call_function,
      then_function: then_function,
    }
  }



  const empty_initial_values = {
    testo: '', allegati: [] as number[]
  }
  let initial = cloneDeep(empty_initial_values);
  if (prold && stold) {
    const last = stold.versioni[stold.versioni.length - 1];
    initial.testo = last.testo;
    initial.allegati = last.allegati;
  }
  return (
    <div>
      <ErrorBlockGeneric error={error} />
      <Formik
        initialValues={initial}
        enableReinitialize
        onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
          const submitting_data = {
            thread: props.thread,
            versioni: [{
              testo: values.testo,
              allegati: values.allegati
            },]
          };
          const { call_function, then_function } = smistaSuccessivo(submitting_data)
          call_function()
            .then(then_function)
            .catch((error: APIError) => handle_drf_form_error(
              error, actions, setError,
              // @ts-ignore
              deserialize_versioned
            )
            )
            .finally(() => actions.setSubmitting(false))
        }
        }
      >
        {({ values, isSubmitting, setFieldValue, handleReset }) => {
          return (
            <>
              <Form className="form needs-validation">
                <AllegatoMap allegati={values.allegati} />
                <div>
                  <Anteprima fieldname="testo" />
                </div>
                <TextHelper field={textarea} fieldname="testo" />
                <FormGroupBootstrap
                  name="testo" type="textarea" ref={textarea}
                />
                <div className="d-flex justify-content-end">
                  <ConfirmedButton
                    type="warning"
                    onSubmit={handleReset}>
                    Reset
                  </ConfirmedButton>
                  <button type="submit" disabled={isSubmitting}
                    className="mx-1 btn btn-primary">Invia</button>
                </div>
              </Form>
              <PubblicaAllegato setAllegati={(val) => setFieldValue("allegati", val)} allegatiStr="allegati" />
            </>
          );
        }}
      </Formik>
    </div >
  );
}

export default NuovoCommento;
