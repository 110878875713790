import React, { useEffect, useState } from 'react';
import { Link, useParams, } from "react-router-dom";

import {
  get_problema_thread, post_letto_thread
} from '../api';
import {
  Problema, NuovoCommento, LoadingCircle,
} from '../components';
import { login_url } from '../globals'
import { useLogin, useThread } from '../reducers';
import { emptyFeed, fetchFeed, renderFeed } from '../utils/feed';


type Thread = Awaited<ReturnType<typeof get_problema_thread>>;

const filterFactory = (thread: Thread) => {
  return {
    commenti: { thread: thread.id },
    commenti_shortlist: { thread: thread.id },
    risposte: { problema: thread.problema.id },
    assegnazione: { problema: thread.problema.id },
    modifiche_commenti: { commento__thread: thread.id },
    modifiche_problemi: { thread: thread.id },
    citazioni: { thread: thread.id },
  }
}



const ThreadProblema = (props: {}) => {
  const login = useLogin()
  const [thread, setThread] = useState<Thread | null>(null)
  const threads = useThread(props);
  const [commenta, setCommenta] = useState(false);
  const [feed, setFeed] = useState(emptyFeed)
  const params = useParams<{ pk: string }>()

  const pk = parseInt(params.pk as string);

  useEffect(() => {
    get_problema_thread(pk).then(thread => {
      setThread(thread)
      fetchFeed(filterFactory(thread)).then(setFeed)
    })
    post_letto_thread(pk);
  }, [pk])

  useEffect(() => {
    if (!thread) {
      document.title = `#${pk} - Owlitrack`;
    } else {
      document.title = `${thread.titolo}`;
    }
  }, [thread])

  if (!login.logged_in) {
    login_url()
  }
  if (!thread) {
    return (
      <LoadingCircle />
    );
  }

  const rendered_feed = renderFeed(feed, threads, prob => prob.id === pk, true, true);
  return (
    <div className="container-fluid mb-4">
      <h1>Thread</h1>
      <Problema thread={thread} />
      {rendered_feed}
      <div className="d-flex justify-content-between">
        <button className="btn btn-primary" onClick={() => setCommenta(true)}>
          Commenta
        </button>
        <Link to={`/thread/problema/${pk}/rispondi/`}>
          <button className="btn btn-success">Consegna risposta</button>
        </Link>
      </div>
      {commenta && <NuovoCommento thread={pk} />}
    </div>
  );
}


export default ThreadProblema;
