import React, { Component } from 'react';
import { Formik, Form, FormikHelpers as FormikActions, useFormikContext } from 'formik';
// @ts-ignore
import EmojiPicker from 'emoji-picker-react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { FormGroupBootstrap } from '../components'
import { displayUser } from '../utils'
import { usePartecipante, useThread } from '../reducers';



// Questo codice è stato copiato da https://gist.github.com/fnicollier/4258461.
// Modifiche fatte da OrsoBruno per farlo funzionare con lo schifoso react

export function insertAtCaret(ref: React.RefObject<HTMLDivElement>, text: string) {
  if (!ref.current) {
    return;
  }
  const txtarea = ref.current.firstChild as HTMLTextAreaElement
  let strPos = 0;
  // Browser
  const br = ((txtarea.selectionStart || txtarea.selectionStart == 0) ?
    // @ts-ignore
    "ff" : (document.selection ? "ie" : false));
  if (br == "ie") {
    txtarea.focus();
    // @ts-ignore
    const range = document.selection.createRange();
    range.moveStart('character', -txtarea.value.length);
    strPos = range.text.length;
  }
  else if (br == "ff") strPos = txtarea.selectionStart;

  const front = (txtarea.value).substring(0, strPos);
  const back = (txtarea.value).substring(strPos, txtarea.value.length);
  const result = front + text + back;
  return result;
}


interface IProps {
  field: React.RefObject<HTMLDivElement>,
  fieldname: string,
}

interface ownProps {
  setField: (_: string) => void
}

const CitazioneFilter = (props: ownProps) => {
  const { setField } = props
  const partecipanti = usePartecipante(props);
  const thread = useThread(props);
  return <Formik
    initialValues={{ testo: "" }}
    onSubmit={() => {}}
  >
    {({ values }) => {
      let results;
      const rem = values.testo.match(/^(@|#)(.*)$/)
      if (rem) {
        if (rem[1] == "@") {
          const filtrati = partecipanti.filter(part =>
            part.user.first_name.toLowerCase().includes(rem[2].toLowerCase()) ||
            part.user.last_name.toLowerCase().includes(rem[2].toLowerCase())
          ).slice(0, 4)
          results = filtrati.map(part => <li key={part.id}>
            <div className="citazione-link d-flex flex-row" onClick={() => setField(` @${part.user.username} `)}>
              <div>
                {part.avatar &&
                  <img
                    src={`/avatars/${part.avatar}/`}
                    alt={displayUser(part.user)}
                    style={{ maxWidth: '30px' }}
                  />
                }
              </div>
              <div>
                <h6>{displayUser(part.user)}</h6>
                <p className="text-muted">@{part.user.username}</p>
              </div>
            </div>
          </li>)
        } else if (rem[1] == "#") {
          const filtrati = thread.filter(thread =>
            thread.id.toString().includes(rem[2]) ||
            thread.titolo.toLowerCase().includes(rem[2])
          ).slice(0, 4)
          results = filtrati.map(thread => <li key={thread.id}>
            <div className="citazione-link" onClick={() => setField(` #${thread.id} `)}>
              <p>#{thread.id} - {thread.titolo}</p>
            </div>
          </li>)
        }
      }

      return <Form className="form needs-validation px-1 py-1">
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {results}
        </ul>
        <FormGroupBootstrap name="testo" type="text"
          help_text="Inizia con @ per citare persone, con # per citare thread"
        />
      </Form>
    }}
  </Formik >
}

/**
 * Cazzillo di merda per far inserire ai boomer le emoticons e lo spoiler
 * @param {funzione} setFieldValue la funzione di formik per cambiare il valore dei valori del form.
 */
// const TextHelper = React.forwardRef((props: IProps, ref) => {
const TextHelper = (props: IProps) => {

  const { setFieldValue } = useFormikContext();
  const { fieldname, field } = props;
  const setField = (val: string) => setFieldValue(fieldname, insertAtCaret(field, val));

  return (
    <div className="row my-2">
      <div className="col-2">Shortcut:</div>
      <div className="col-2">
        <OverlayTrigger
          trigger="click"
          placement="top"
          overlay={
            <Popover id={`emoji-picker`}>
              <EmojiPicker
                onEmojiClick={(emojiCode: string, emojiData: any) => setField(`:` + emojiData.name + `:`)}
              />
            </Popover>
          }
        >
          <button className="btn btn-info" type="button">Emoji</button>
        </OverlayTrigger>
      </div>
      <div className="col-3">
        <OverlayTrigger
          placement="top"
          trigger="click"
          overlay={
            <Popover id={"suggerimento-citazioni"} style={{ maxWidth: '800px', padding: '10px', minWidth: '400px' }}>
              <CitazioneFilter setField={setField} />
            </Popover>
          }
        >
          <button className="btn btn-info" type="button">Citazione</button>
        </OverlayTrigger>
      </div>
      <div className="col-2">
        <button className="btn btn-info" type="button" onClick={() => setField(`[spoiler][/spoiler]`)}>
          Spoiler
        </button>
      </div>
    </div>
  );
  // })
}

export default TextHelper;
